.covid-pass {
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  background: repeating-linear-gradient(135deg,#ffc500,#ffc500 80px,#0000 0,#0000 160px);
}

.covid-pass-content {
  margin: 20px;
  border-radius: 5px;
  background: #fff;
}

.covid-pass-disclaimer {
  color: $light-text-color;
  font-size: 11px;
  padding: 20px;
  line-height: 13px;
}

.covid-pass-form {
  border: 3px dashed $border-color;
  margin: 15px 20px;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
}

.covid-pass-loading-text {
  color: $light-text-color;
  font-size: 14px;
  margin: 15px 0;
  font-weight: 500;
}

.covid-pass-title {
  font-weight: 600;
  padding: 20px;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
}

.covid-pass-about {
  padding: 0 20px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.covid-pass-instructions {
  font-size: 14px;
  line-height: 22px;
  padding: 20px;
}

.covid-pass-error {
  margin: 0 20px 30px 20px;
  color: #fff;
  background: #e74c3c;
  border-radius: 3px;
  font-size: 14px;
  padding: 10px 15px;
  line-height: 20px;
  font-weight: 500;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: $primary;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: $primary;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
