@font-face {
  font-family: 'Flappy Bird';
  src: url('./fonts/FB.eot');
  src: url('./fonts/FB.woff') format('woff'),
       url('./fonts/FB.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.fm-flappy {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #fee7a2;
  position: relative;
  height: 100%;
  height: 100vh;
}

.fm-flappy-loading {
  background: #fee7a2;
  height: 500px;
}

canvas {
  max-height: 100%;
  max-width: 100%;
  z-index: 99999999;
}

iframe {
  position: fixed;
  top: 0px;
  left: 50%;
  margin-left: -76px;
}

.fm-flappy-background-loading {
  position: fixed;
  top: 200px;
  left: 0;
  right: 0;
  z-index: 1;
}
