.love {
  max-width: 500px;
  margin: 0 auto;
}

.love-box {
  background: $primary;
  padding: 50px 0;
}

.love-title-top {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 60px;
  line-height: 80px;
}

.love-title-bottom {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 60px;
  line-height: 60px;
}

.love-note {
  font-size: 16px;
  text-align: center;
  padding: 20px 20px 0 20px;
  line-height: 25px;
}

.love-content {
  padding: 20px;
}

.love-content .fm-input {
  text-align: center;
}

.love-footer {
  font-size: 12px;
  color: #aaa;
  text-align: center;
  padding: 0 10px;
  margin: 0 0 20px 0;
}
