.fm-bike {
  background: #ff206e;
  font-family: 'Noto Sans', sans-serif;
}

.fm-bike-loading, .fm-bike-error {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@-moz-keyframes wheel-spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes wheel-spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes wheel-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.fm-bike-loading-spinner {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/bike-wheel.png');
  width: 80px;
  height: 80px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 40px;
  -webkit-animation: wheel-spin 3s linear infinite;
  -moz-animation: wheel-spin 3s linear infinite;
  animation: wheel-spin 3s linear infinite;
}

.fm-bike-error-title {
  color: #FFF;
  font-weight: 800;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 1px;
  margin: 10px;
}

.fm-bike-error-text {
  font-size: 14px;
  margin: 20px;
  color: #fff;
  text-align: center;
  line-height: 25px;
}

.fm-bike-header {
  position: relative;
  font-size: 0;
}

.fm-bike-header-wordmark {
  height: 20px;
  margin: 10px auto;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.fm-bike-header-background {
  width: 100%;
}

.fm-bike-header-title {
  position: absolute;
  left: 20px;
  bottom: 30px;
  width: 65%;
}

.fm-bike-content {
  background: #fff;
  padding: 15px 0;
}

.fm-bike-title {
  font-weight: 800;
  text-align: center;
  font-size: 25px;
  line-height: 40px;
}

.fm-bike-redeemed {
  font-size: 25px;
  font-weight: 800;
  font-style: italic;
  text-align: center;
  line-height: 32px;
  margin: 25px 20px 35px 20px;
}

.fm-bike-description {
  margin: 15px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  opacity: 0.9;
}

.fm-bike-instructions {
  margin: 10px 15px 25px 15px;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  opacity: 0.9;
}

@keyframes button-wobble {
  0%, 75%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  79% {
    -webkit-transform: translateX(-8px) rotate(-2deg);
    transform: translateX(-8px) rotate(-2deg);
  }
  83% {
    -webkit-transform: translateX(calc(8px / 2)) rotate(2deg);
    transform: translateX(calc(8px / 2)) rotate(2deg);
  }
  87% {
    -webkit-transform: translateX(calc(-8px / 2)) rotate(calc(-2deg / 1.8));
    transform: translateX(calc(-8px / 2)) rotate(calc(-2deg / 1.8));
  }
  90% {
    -webkit-transform: translateX(calc(8px / 3.3)) rotate(calc(2deg / 3));
    transform: translateX(calc(8px / 3.3)) rotate(calc(2deg / 3));
  }
  94% {
    -webkit-transform: translateX(calc(-8px / 5.5)) rotate(calc(-2deg / 5));
    transform: translateX(calc(-8px / 5.5)) rotate(calc(-2deg / 5));
  }
}

.fm-bike-button {
  -webkit-appearance: none;
  cursor: pointer;
  background: #000;
  color: #fff;
  font-family: 'Noto Sans', sans-serif;
  animation: button-wobble 4s ease infinite;
  font-weight: 800;
  font-style: italic;
  font-size: 20px;
  text-align: center;
  display: block;
  width: 300px;
  margin: 50px auto;
  padding: 5px 0 25px 10px;
  line-height: 25px;
  position: relative;
  z-index: 0;
}

.fm-bike-button::before {
  z-index: -1;
  position: absolute;
  content: "";
  background: #ff206e;
  left: 10px;
  bottom: 10px;
  right: -10px;
  top: -10px;
}

.fm-bike-terms {
  font-size: 10px;
  color: #fff;
  line-height: 15px;
  margin: 20px 15px;
  opacity: 0.8;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fm-bike-confetti {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadeOutAnimation ease 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fm-bike-wheel {
  width: 100%;
  height: 380px;
  position: relative;
  cursor: wait;
  overflow: hidden;
  margin-bottom: 30px;
}

.fm-bike-wheel-spinner {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-spinner-1.png');
  animation: bikeSpinnerLights 1s infinite;
  -webkit-transition: all 10s cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transform: rotateZ(0deg);
}

.fm-bike-wheel-frame {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-frame.png');
}

.fm-bike-wheel-spinner, .fm-bike-wheel-frame {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fm-bike-controls-won {
  margin: 10px 15px 25px 15px;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
}

@keyframes bikeSpinnerLights {
  0% { background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/bike-spinner-1.png'); }
  50%  { background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/bike-spinner-2.png'); }
  100% { background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/bike-spinner-1.png'); }
}

.fm-bike-wheel-spinner-m100 {
  -webkit-transform: rotateZ(3622.5deg);
}

.fm-bike-wheel-spinner-m10 {
  -webkit-transform: rotateZ(3667.5deg);
}

.fm-bike-wheel-spinner-c20 {
  -webkit-transform: rotateZ(3712.5deg);
}

.fm-bike-wheel-spinner-m15 {
  -webkit-transform: rotateZ(3757.5deg);
}

.fm-bike-wheel-spinner-c5 {
  -webkit-transform: rotateZ(3802.5deg);
}

.fm-bike-wheel-spinner-m20 {
  -webkit-transform: rotateZ(3847.5deg);
}

.fm-bike-wheel-spinner-c10 {
  -webkit-transform: rotateZ(3892.5deg);
}

.fm-bike-wheel-spinner-m5 {
  -webkit-transform: rotateZ(3937.5deg);
}
