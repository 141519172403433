.fm-quiz-progress {
  margin-bottom: 25px;
}

.fm-quiz-progress-label {
  color: #fff;
  font-weight: 500;
  line-height: 30px;
}

.fm-quiz-progress-bar {
  background: rgba(255, 255, 255, 0.1);
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0;
}

.fm-quiz-progress-bar-inner {
  background: $primary;
  height: 10px;
  transition: ease width 0.5s;
}
