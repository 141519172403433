.download {
  max-width: 500px;
  margin: 0 auto;
}

.download-wordmark {
  width: 100px;
  height: 20px;
  margin: 0 auto 30px auto;
  display: block;
}

.download-app {
  display: block;
  margin: 30px auto 20px auto;
  text-align: center;
}

.download-box {
  background: $primary;
  padding: 50px 0;
}

.download-treasure {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 35px;
  line-height: 55px;
}

.download-hunt {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 65px;
  line-height: 70px;
}

.download-note {
  font-size: 16px;
  text-align: center;
  padding: 20px 20px 0 20px;
  line-height: 25px;
}

.download-footer {
  font-size: 12px;
  color: #aaa;
  text-align: center;
  margin: 100px 0 50px 0;
}
