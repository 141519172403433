.hunt-redeemed {
  max-width: 500px;
  margin: 0 auto;
}

.hunt-redeemed-box {
  background: $primary;
  padding: 50px 0;
}

.hunt-redeemed-try {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 70px;
  line-height: 90px;
}

.hunt-redeemed-again {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 40px;
  line-height: 40px;
}

.hunt-redeemed-note {
  font-size: 16px;
  text-align: center;
  padding: 20px 20px 0 20px;
  line-height: 25px;
}

.hunt-redeemed-footer {
  font-size: 12px;
  color: #aaa;
  text-align: center;
  margin: 100px 0 50px 0;
}
