@import './components/quiz-card/quiz-card.scss';
@import './components/quiz-introduction/quiz-introduction.scss';
@import './components/quiz-progress/quiz-progress.scss';

.fm-quiz {
  height: 100%;
  width: 100%;
  background: #1B263B;
  padding: 25px 25px 100px 25px;
  line-height: 28px;
  flex: 1;
}
