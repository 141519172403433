.fm-quiz-introduction-title {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #fff;
  margin: 5px 0 25px 0;
  line-height: 40px;
}

.fm-quiz-introduction-photo {
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 200px;
}

.fm-quiz-introduction-card {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.fm-quiz-introduction-items {
  padding: 5px 0 10px 0;
}

.fm-quiz-introduction-heading {
  font-weight: 700;
  padding: 15px 15px 10px 15px;
  letter-spacing: 0.2px;
}

.fm-quiz-introduction-paragraph {
  font-size: 15px;
  line-height: 25px;
  padding: 0 15px 5px 15px;
}

.fm-quiz-introduction-button {
  padding: 15px;
}

.fm-quiz-introduction-start {
  background: $primary;
  height: 50px;
  display: block;
  width: 100%;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.2px;
  border-radius: 5px;
  cursor: pointer;
}
