.fm-quiz-card {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.fm-quiz-card-question {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  padding: 15px;
  margin: 5px 0;
}

.fm-quiz-card-answers {
  display: flex;
  padding: 0 10px 15px 10px;
  flex-flow: row wrap;
}

.fm-quiz-card-answer {
  width: 50%;
  height: 150px;
  padding: 5px;
  cursor: pointer;
}

.fm-quiz-card-answer-box {
  border: 2px solid rgba(27, 38, 59, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: ease all 0.1s;
}

.fm-quiz-card-answer-label {
  padding: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  transition: ease all 0.1s;
}

.fm-quiz-card-button {
  padding: 15px;
}

.fm-quiz-card-next {
  background: $primary;
  height: 50px;
  display: block;
  width: 100%;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.2px;
  border-radius: 5px;
  cursor: pointer;
}

.fm-quiz-card-answer-selected .fm-quiz-card-answer-box {
  border-color: $primary;
  border-width: 3px;
}

.fm-quiz-card-answer-selected .fm-quiz-card-answer-label {
  color: $primary;
}

.fm-quiz-card-answers-correct .fm-quiz-card-answer-selected .fm-quiz-card-answer-box {
  border-width: 0px;
  background-color: #4cd137;
}

.fm-quiz-card-answers-correct .fm-quiz-card-answer-selected .fm-quiz-card-answer-label {
  color: #fff;
}
