.wcc {
  height: 100%;
  background: #fd0;
}

.wcc-content {
  height: 100%;
  overflow-y: scroll;
}

.wcc-content-wordmark {
  width: 60px;
  margin: 30px auto 5px auto;
  height: 14px;
  display: block;
}

/* ----- INTRO ----- */

.wcc-content-intro {
  margin: 25px auto;
  background: #fff;
  padding: 20px;
  max-width: 800px;
}

.wcc-content-intro-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin: 5px 0;
}

.wcc-content-intro-subtitle {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  margin: 12px 0 24px 0;
}

.wcc-content-intro-correct {
  color: #fff;
  background: #236FA6;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  line-height: 20px;
  border-radius: 3px;
  margin: 25px 0;
}

.wcc-content-intro-text {
  font-size: 16px;
  line-height: 22px;
  margin: 20px 0;
}

.wcc-content-intro-button {
  display: block;
  background: #ff206e;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  padding: 10px 30px;
  margin: 35px auto 40px auto;
  cursor: pointer;
}

.wcc-content-intro-disclaimer {
  font-size: 10px;
  color: #999;
  line-height: 14px;
}

/* ----- FORM ----- */

.wcc-content-form-iframe {
  position: relative;
  left: 0;
  right: 0;
  margin: 0;
  flex: 1;
}

.wcc-content-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wcc-content-form-bar {
  background: #fff;
  border-bottom: 0px solid #ccc;
  padding: 0 20px 0 20px;
  max-height: 0;
  overflow: hidden;
  animation: wcc-show-bar 120s ease;
  animation-fill-mode: forwards;
}

@keyframes wcc-show-bar {
  0% {
    padding: 0 20px 0 20px;
    max-height: 0;
  }
  99% {
    padding: 0 20px 0 20px;
    max-height: 0;
  }
  100% {
    padding: 15px 20px 20px 20px;
    max-height: 100%;
    border-bottom: 1px solid #ccc;
  }
}


.wcc-content-form-bar-button {
  display: block;
  background: #111;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 30px;
  margin: 15px 0 0 0;
  cursor: pointer;
  width: 100%;
}

.wcc-content-form-bar-text {
  font-size: 12px;
  color: #666;
  line-height: 18px;
  text-align: center;
}

/* ----- DONE ----- */

.wcc-content-done {
  margin: 25px auto;
  background: #fff;
  padding: 20px;
  max-width: 800px;
}

.wcc-content-done-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin: 5px 0;
}

.wcc-content-done-subtitle {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 20px;
  margin: 16px 0 24px 0;
}

.wcc-content-done-text {
  font-size: 16px;
  line-height: 22px;
  margin: 15px 0;
  text-align: center;
}

.wcc-content-done-file {
  margin: 20px 0;
}

.wcc-content-done-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 50px 0;
}

.wcc-content-done-loading-text {
  color: #9C9B9D;
  font-size: 14px;
  margin: 15px 0;
  font-weight: 500;
}

.wcc-content-done-loading-note {
  font-size: 16px;
  line-height: 22px;
  margin: 15px 0;
  text-align: center;
}

.wcc-content-done-button {
  display: block;
  background: #111;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  padding: 8px 30px;
  margin: 15px 0 0 0;
  cursor: pointer;
  width: 100%;
}

.wcc-content-done-error {
  background: #e74c3c;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  padding: 9px 12px;
  line-height: 21px;
  font-size: 15px;
}
