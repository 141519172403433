.fm-parking {
  background: #4E4E4E;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.fm-parking-background {
  background-image: url('~/public/assets/parking/background-one.png');
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: calc(100vw * 1.6);
}

.fm-parking-2 {
  background: #66BF33;
}

.fm-parking-1 .fm-parking-background {
  background-image: url('~/public/assets/parking/background-one.png');
}

.fm-parking-2 .fm-parking-background {
  background-image: url('~/public/assets/parking/background-two.png');
}

.fm-parking-3 .fm-parking-background {
  background-image: url('~/public/assets/parking/background-three.png');
}

.fm-parking-header {
  background-image: url('~/public/assets/parking/header.png');
  height: calc(100vw * 0.25);
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  background-size: contain;
}

.fm-parking-instruction {
  position: absolute;
  top: 25vw;
  left: 15vw;
  right: 15vw;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  padding: 8px 10px;
  text-align: center;
  border-radius: 10px;
  line-height: 22px;
  margin: 0 auto;
  display: block;
}

.fm-parking-terms {
  color: #282828;
  font-size: 12px;
  margin: 10px 15px;
}

.fm-parking-card {
  background: #fff;
  position: absolute;
  top: 10vw;
  left: 5vw;
  right: 5vw;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.fm-parking-card-header {
  background-image: url('~/public/assets/parking/header.png');
  height: calc(100vw * 0.25);
  background-position: 50%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 5px 0;
}

.fm-parking-card-instruction {
  width: 100%;
  height: 80px;
  margin: 20px 0;
  position: relative;
}

.fm-parking-card-instruction-touch {
  background-image: url('~/public/assets/parking/touch.png');
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  animation: fm-touch 4s ease infinite;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
}

.fm-parking-card-instruction-left {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  height: 100%;
  background-image: url('~/public/assets/parking/left.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.fm-parking-card-instruction-right {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  height: 100%;
  background-image: url('~/public/assets/parking/right.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.fm-parking-card-title {
  font-size: 18px;
  font-weight: 800;
  margin: 0 10px 15px 10px;
  text-align: center;
}

.fm-parking-card-subtitle {
  font-size: 18px;
  font-weight: 800;
  margin: 20px 10px 10px 10px;
  text-align: center;
}

.fm-parking-card-text {
  font-size: 14px;
  margin: 5px 20px 15px 20px;
  line-height: 21px;
  text-align: center;
}

.fm-parking-card-close {
  font-size: 14px;
  margin: 5px 20px 15px 20px;
  line-height: 21px;
  text-align: center;
  color: #999;
  font-style: italic;
}

@keyframes fm-touch {
  0% {
    background-position: 50% 50%;
  }
  25% {
    background-position: 30% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  75% {
    background-position: 70% 50%;
  }
  100% {
    background-position: 50% 50%;
  }
}

.fm-parking-card-button {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background: #ff206e;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.2px;
  padding: 8px 0;
  width: 200px;
  margin: 25px auto 30px auto;
  display: block;
}

.fm-parking-slider {
  position: absolute;
  -webkit-appearance: none;
  left: 2%;
  top: 0;
  right: 2%;
  bottom: 0;
  width: 96%;
  height: 100%;
  background: transparent;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  z-index: 99999;
}

.fm-parking-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25vw;
  height: 100%;
  background: rgba(0,0,0,0);
  box-shadow: none;
  background-image: url('~/public/assets/parking/scooter.png');
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}

.fm-parking-slider::-webkit-slider-runnable-track {
  height: 100%;
}

.fm-parking-slider::-moz-range-thumb {
  appearance: none;
  width: 25vw;
  height: calc(25vw * 1.5);
  background-image: url('~/public/assets/parking/scooter.png');
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}

.fm-parking-check {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background: #fff;
  color: #ff206e;
  border: none;
  border-radius: 10px;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.2px;
  padding: 8px 0;
  width: 200px;
  margin: 0 auto;
  position: absolute;
  bottom: 20vw;
  left: 0;
  right: 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
  z-index: 999999;
}

.fm-parking-photo {
  height: calc(60vw * 1.75);
  width: 60vw;
  position: absolute;
  top: -100vw;
  animation: fm-show-phone, fm-grow-phone;
  animation-duration: 3s, 1s;
  animation-delay: 0s, 3s;
  animation-timing-function: ease, ease;
  animation-fill-mode: forwards, forwards;
  z-index: 999999;
}

@keyframes fm-show-phone {
  0% {
    top: -100vw;
  }
  33% {
    top: 25vw;
  }
  100% {
    top: 25vw;
    height: calc(60vw * 1.75);
    width: 60vw;
  }
}

@keyframes fm-grow-phone {
  100% {
    top: 25vw;
    width: 80vw;
    left: 10vw;
    height: calc(80vw * 1.75);
  }
}

.fm-parking-photo-phone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('~/public/assets/parking/phone-photo.png');
  height: calc(60vw * 1.75);
  width: 60vw;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  animation: fm-fill-phone 4s ease;
  animation-fill-mode: forwards;
}

@keyframes fm-fill-phone {
  80% {
    background-image: url('~/public/assets/parking/phone-photo.png');
  }
  100% {
    background-image: url('~/public/assets/parking/phone-fill.png');
  }
}

.fm-parking-photo::before {
  content: "";
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  width: 10px;
  background: #ff206e;
  box-shadow: 0 0 70px 20px #ff206e;
  clip-path: inset(0);
  animation: x 1s ease-in-out infinite alternate, y 2s ease-in-out infinite;
}

@keyframes x {
  to {
    transform:translateX(-90%);
    left: 90%;
  }
}

@keyframes y {
   33% {
     clip-path:inset(0 0 0 -100px);
   }
   50% {
     clip-path:inset(0 0 0 0);
   }
   83%{
     clip-path:inset(0 -100px 0 0);
   }
}

.fm-parking-photo-phone-content {
  margin: 29% 5% 0 5%;
  animation: fm-show-result;
  animation-duration: 1s;
  animation-delay: 3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  opacity: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

@keyframes fm-show-result {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fm-parking-phone-result {
  height: 50px;
  margin: 20px auto;
  display: block;
}

.fm-parking-phone-title {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  line-height: 26px;
  margin: 10px;
}

.fm-parking-phone-text {
  font-size: 14px;
  margin: 10px 15px;
  text-align: center;
  line-height: 20px;
}

.fm-parking-phone-image {
  margin: 25px auto;
  width: 50%;
  display: block;
}

.fm-parking-phone-button {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background: #ff206e;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.2px;
  padding: 5px 0;
  width: 200px;
  margin: 30px auto 10px auto;
  display: block;
}

.fm-parking-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-parking-loader {
  width: 20px;
  height: 12px;
  display: block;
  margin: auto;
  position: relative;
  border-radius: 4px;
  color: #FFF;
  background: currentColor;
  box-sizing: border-box;
  animation: animloader 0.6s 0.3s ease infinite alternate;
}

.fm-parking-loader::after,
.fm-parking-loader::before {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 12px;
  background: currentColor;
  position: absolute;
  border-radius: 4px;
  top: 0;
  right: 110%;
  animation: animloader  0.6s ease infinite alternate;
}

.fm-parking-loader::after {
  left: 110%;
  right: auto;
  animation-delay: 0.6s;
}

@keyframes animloader {
  0% {
    width: 20px;
  }
  100% {
    width: 48px;
  }
}

.fm-parking-error {
  position: absolute;
  top: 30%;
  left: 15%;
  right: 15%;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.fm-parking-error-title {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  line-height: 24px;
  margin: 20px 20px 15px 20px;
  text-transform: capitalize;
}

.fm-parking-error-text {
  margin: 15px 20px 20px 20px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
}
