.fm-birthday {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-background.png');
  background-size: 206px;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
}

.fm-birthday-loading {
  height: 100vh;
}

.fm-birthday-content {
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
}

.fm-birthday-header-banner {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-banner.png');
  height: 100px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  margin: 10px 10px 30px 10px;
}

.fm-birthday-header-text {
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  margin: 20px 10px;
}

.fm-birthday-wheel {
  width: 100%;
  height: 380px;
  position: relative;
  cursor: wait;
  overflow: hidden;
}

.fm-birthday-wheel-spinner {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-spinner-1.png');
  animation: spinnerLights 1s infinite;
  -webkit-transition: all 10s cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transform: rotateZ(0deg);
}

.fm-birthday-wheel-frame {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-frame.png');
}

.fm-birthday-wheel-spinner, .fm-birthday-wheel-frame {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fm-birthday-controls-won {
  margin: 10px 20px;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
}

@keyframes spinnerLights {
  0% { background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-spinner-1.png'); }
  50%  { background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-spinner-2.png'); }
  100% { background-image: url('https://storage.googleapis.com/flamingo-static/images/promo/birthday-spinner-1.png'); }
}

.fm-birthday-footer {
  font-size: 12px;
  color: #aaa;
  text-align: center;
  margin: 30px 10px;
}

.fm-birthday-wheel-spinner-m1000 {
  -webkit-transform: rotateZ(3622.5deg);
}

.fm-birthday-wheel-spinner-m5 {
  -webkit-transform: rotateZ(3667.5deg);
}

.fm-birthday-wheel-spinner-u10 {
  -webkit-transform: rotateZ(3712.5deg);
}

.fm-birthday-wheel-spinner-c5 {
  -webkit-transform: rotateZ(3757.5deg);
}

.fm-birthday-wheel-spinner-g1 {
  -webkit-transform: rotateZ(3802.5deg);
}

.fm-birthday-wheel-spinner-m100 {
  -webkit-transform: rotateZ(3847.5deg);
}

.fm-birthday-wheel-spinner-c10 {
  -webkit-transform: rotateZ(3892.5deg);
}

.fm-birthday-wheel-spinner-m10 {
  -webkit-transform: rotateZ(3937.5deg);
}

.fm-birthday-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-birthday-wheel-button {
  background-color: #FF206E;
  border-radius: 4px;
  border: 0;
  box-shadow: rgba(1, 60, 136, 0.5) 0 -1px 3px 0 inset, rgba(0, 44, 97, 0.1) 0 3px 6px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Space Grotesk",-apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 20px;
  min-height: 56px;
  min-width: 120px;
  padding: 16px 20px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all .2s cubic-bezier(.22, .61, .36, 1);
  flex: 1;
}

.fm-birthday-wheel-button:hover {
  background-color: #d21859;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-66 {
    padding: 16px 44px;
    min-width: 150px;
  }
}

.fm-birthday-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  height: 100vh;
}

.fm-birthday-error-text {
  font-size: 16px;
  margin: 10px 20px;
  line-height: 22px;
  text-align: center;
}
