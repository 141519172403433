.fm-advent {
  background: #22491a;
  font-family: 'Noto Sans', sans-serif;
}

.fm-advent-loading, .fm-advent-error {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.fm-advent-loading-spinner {
  background-image: url('~/public/assets/advent/advent-g-2.png');
  width: 80px;
  height: 80px;
  background-color: #3c672f;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 40px;
  -webkit-animation:spin 3s linear infinite;
  -moz-animation:spin 3s linear infinite;
  animation:spin 3s linear infinite;
}

.fm-advent-error-title {
  color: #FFF;
  font-weight: 800;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 1px;
  font-family: 'Lilita One', sans-serif;
  margin: 10px;
}

.fm-advent-error-text {
  font-size: 14px;
  margin: 20px;
  color: #fff;
  text-align: center;
  line-height: 25px;
}

.fm-advent-header {
  margin: 30px 15px;
}

.fm-advent-header-wordmark {
  height: 20px;
  margin: 10px auto;
  display: block;
}

.fm-advent-header-title {
  color: #FFF;
  font-weight: 800;
  text-align: center;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 1px;
  font-family: 'Lilita One', sans-serif;
}

.fm-advent-header-description {
  margin: 20px 15px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.9;
}

.fm-advent-header-instructions {
  margin: 20px 15px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.9;
}

.fm-advent-boxes {
  height: 850px;
  display: grid;
  gap: 10px;
  margin: 30px 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.fm-advent-box {
  background: #1b3b15;
  color: #fff;
  background-repeat: no-repeat;
  position: relative;
  z-index: 9;
  transform: translateZ(0);
  cursor: pointer;
}

.fm-advent-box-inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  transform-origin: left;
  transition: all 2s ease-in-out;
  box-shadow: #00000000 10px 0 20px;
  z-index: 999;
}

.fm-advent-box-1 { grid-column: 1 / 3; grid-row: 1 / 3; }
.fm-advent-box-2 { grid-column: 3 / 5; grid-row: 1 / 2; }
.fm-advent-box-3 { grid-column: 5 / 6; grid-row: 1 / 4; }
.fm-advent-box-4 { grid-column: 1 / 3; grid-row: 3 / 4; }
.fm-advent-box-5 { grid-column: 3 / 4; grid-row: 2 / 4; }
.fm-advent-box-6 { grid-column: 4 / 5; grid-row: 2 / 3; }
.fm-advent-box-7 { grid-column: 1 / 4; grid-row: 4 / 5; }
.fm-advent-box-8 { grid-column: 4 / 5; grid-row: 3 / 5; }
.fm-advent-box-9 { grid-column: 5 / 6; grid-row: 4 / 5; }
.fm-advent-box-10 { grid-column: 1 / 2; grid-row: 5 / 8; }
.fm-advent-box-11 { grid-column: 2 / 4; grid-row: 5 / 7; }
.fm-advent-box-12 { grid-column: 4 / 6; grid-row: 5 / 6; }
.fm-advent-box-13 { grid-column: 2 / 3; grid-row: 7 / 8; }
.fm-advent-box-14 { grid-column: 3 / 4; grid-row: 7 / 9; }
.fm-advent-box-15 { grid-column: 4 / 5; grid-row: 6 / 9; }
.fm-advent-box-16 { grid-column: 5 / 6; grid-row: 6 / 8; }
.fm-advent-box-17 { grid-column: 1 / 3; grid-row: 8 / 10; }
.fm-advent-box-18 { grid-column: 3 / 5; grid-row: 9 / 10; }
.fm-advent-box-19 { grid-column: 5 / 6; grid-row: 8 / 11; }
.fm-advent-box-20 { grid-column: 1 / 2; grid-row: 10 / 12; }
.fm-advent-box-21 { grid-column: 2 / 3; grid-row: 10 / 11; }
.fm-advent-box-22 { grid-column: 2 / 3; grid-row: 11 / 12; }
.fm-advent-box-23 { grid-column: 3 / 5; grid-row: 10 / 12; }
.fm-advent-box-24 { grid-column: 1 / 4; grid-row: 12 / 13; }
.fm-advent-box-25 { grid-column: 4 / 6; grid-row: 12 / 13; }
.fm-advent-box-f { grid-column: 5 / 6; grid-row: 11 / 12; }

.fm-advent-box-1 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-1.png'); }
.fm-advent-box-2 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-2.png'); }
.fm-advent-box-3 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-1.png'); }
.fm-advent-box-4 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-2.png'); }
.fm-advent-box-5 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-w-1.png'); }
.fm-advent-box-6 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-4.png'); }
.fm-advent-box-7 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-3.png'); }
.fm-advent-box-8 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-9.png'); }
.fm-advent-box-9 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-8.png'); }
.fm-advent-box-10 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-7.png'); }
.fm-advent-box-11 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-1.png'); }
.fm-advent-box-12 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-6.png'); }
.fm-advent-box-13 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-3.png'); }
.fm-advent-box-14 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-5.png'); }
.fm-advent-box-15 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-w-2.png'); }
.fm-advent-box-16 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-2.png'); }
.fm-advent-box-17 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-4.png'); }
.fm-advent-box-18 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-4.png'); }
.fm-advent-box-19 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-3.png'); }
.fm-advent-box-20 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-2.png'); }
.fm-advent-box-21 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-3.png'); }
.fm-advent-box-22 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-2.png'); }
.fm-advent-box-23 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-r-1.png'); }
.fm-advent-box-24 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-g-1.png'); }
.fm-advent-box-25 .fm-advent-box-inner { background-image: url('~/public/assets/advent/advent-w-3.png'); }
.fm-advent-box-f { grid-column: 5 / 6; grid-row: 11 / 12; background-image: url('~/public/assets/advent/advent-logo.png'); }

.fm-advent-box-gl .fm-advent-box-inner { background-color: #547c35; }
.fm-advent-box-gd .fm-advent-box-inner { background-color: #3c672f; }
.fm-advent-box-rl .fm-advent-box-inner { background-color: #e69e80; }
.fm-advent-box-rd .fm-advent-box-inner { background-color: #e27a5f; }
.fm-advent-box-w .fm-advent-box-inner { background-color: #fff; color: #e27a5f; }
.fm-advent-box-f { background-color: #ff206e; }

.fm-advent-box-sq4 .fm-advent-box-inner { background-position: 75% 75%; background-size: 50%; }
.fm-advent-box-rh2 .fm-advent-box-inner { background-position: 90% 50%; background-size: 35%; }
.fm-advent-box-rh3 .fm-advent-box-inner { background-position: 90% 50%; background-size: 18%; }
.fm-advent-box-rv3 .fm-advent-box-inner { background-position: 50% 90%; background-size: 80%; }
.fm-advent-box-rv2 .fm-advent-box-inner { background-position: 50% 90%; background-size: 70%; }
.fm-advent-box-sq1 .fm-advent-box-inner { background-position: 40px 50%; background-size: 80%; }
.fm-advent-box-f { background-position: 50%; background-size: 100%; }

.fm-advent-box-active .fm-advent-box-inner {
  transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-105deg);
  box-shadow: #00000066 10px 0 20px;
}

@keyframes wobble {
  0%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-8px) rotate(-2deg);
    transform: translateX(-8px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(calc(8px / 2)) rotate(2deg);
    transform: translateX(calc(8px / 2)) rotate(2deg);
  }
  45% {
    -webkit-transform: translateX(calc(-8px / 2)) rotate(calc(-2deg / 1.8));
    transform: translateX(calc(-8px / 2)) rotate(calc(-2deg / 1.8));
  }
  60% {
    -webkit-transform: translateX(calc(8px / 3.3)) rotate(calc(2deg / 3));
    transform: translateX(calc(8px / 3.3)) rotate(calc(2deg / 3));
  }
  75% {
    -webkit-transform: translateX(calc(-8px / 5.5)) rotate(calc(-2deg / 5));
    transform: translateX(calc(-8px / 5.5)) rotate(calc(-2deg / 5));
  }
}

.fm-advent-box-wrong .fm-advent-box-inner {
  animation: wobble 1s ease 1;
}

.fm-advent-box-text {
  font-family: 'Lilita One', sans-serif;
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
  padding: 8px 10px;
}

.fm-advent-box-inside {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
}

.fm-advent-box-value {
  font-family: 'Lilita One', sans-serif;
  font-size: 20px;
  line-height: 25px;
}

.fm-advent-box-type {
  font-family: 'Lilita One', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.fm-advent-terms {
  font-size: 10px;
  color: #547c35;
  margin: 30px 15px;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fm-advent-confetti {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadeOutAnimation ease 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fm-advent-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes slide-in {
  100% { transform: translateY(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateY(0%); }
}

.fm-advent-modal-inner {
  background: #22491a;
  width: 80%;
  border-radius: 3px;
  padding: 7px 15px 15px 15px;
  box-shadow: #00000080 0 3px 20px;
  transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  animation: slide-in 1s forwards;
  -webkit-animation: slide-in 1s forwards;
}

.fm-advent-modal-title {
  font-family: 'Lilita One', sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  margin: 20px 0;
}

.fm-advent-modal-prize {
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin: 15px 0;
}

.fm-advent-modal-description {
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  margin: 15px 0;
}
