.hunt-win {
  max-width: 500px;
  margin: 0 auto;
}

.hunt-win-box {
  background: $primary;
  padding: 50px 0;
}

.hunt-win-value {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 100px;
  line-height: 110px;
}

.hunt-win-free {
  font-family: 'Fugaz One', cursive;
  text-align: center;
  color: white;
  font-size: 25px;
  line-height: 30px;
}

.hunt-win-note {
  font-size: 16px;
  text-align: center;
  padding: 20px 20px 0 20px;
  line-height: 25px;
}

.hunt-win-footer {
  font-size: 12px;
  color: #aaa;
  text-align: center;
  margin: 100px 0 50px 0;
}
